.mapa {
    position: relative;
    min-height: 20rem;
    overflow: hidden;
    border-radius: .5rem;
    margin-top: -2rem;

    &> div {
        margin-top: -2rem;
    }
}