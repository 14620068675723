.navbar-header{
    .nav-item {
        list-style: none;
    }
    .menu-usuario-icon{
        color:black;
    }
}

.button-menu{
    width: 56px;
    height: 56px;
    .fa-bars{
        font-size: 1.75em;
        color: #fff
    }
}

.font-1-25{
    font-size: 1.1em;
    .popover{
        font-size: 0.9em;
    }
}

.btn-login{
    font-weight: bold;
    i{
        color: #fff;
    }
    span{
        display: block;
        color: #fff;
    }
}



nav.topHeaderNavBar.navbar-light {

     .navbar-nav {

        .nav-link {
            border-bottom: 2px solid transparent;
            color: #ffffffc7;
            margin: 0 .25rem;

            &:hover {
                border-bottom: 2px solid #fff;
                color: #fff;
            }
        }

        .active>.nav-link,
        .nav-link.active,
        .nav-link.show,
        .show>.nav-link {
            color: #fff;
        }

    }

    .navbar-toggler {
        color: #fff;
        border-color: #ffffffc7;
    }
}

