:root {
  --checkout-floating-btn--cart-fill: rgba(255, 255, 255, 0.8);
  --checkout-floating-btn--cart-svg-hover-fill: rgba(255, 255, 255);
  --checkout-floating-btn--cart-number: rgba(255, 255, 255, 0.85);
}

.checkout-floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 4rem;
  height: 4rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  z-index: 3;

  .cart {
    &__button {
      position: relative;
    }
    &__svg {
      max-width: 24px;
      height: auto;
      position: relative;
      left: -5px;
      fill: var(--checkout-floating-btn--cart-fill);
    }
    &__svg:hover {
      fill: var(--checkout-floating-btn--cart-svg-hover-fill);
    }
    &__number {
      background: var(--checkout-floating-btn--cart-number);
      border-radius: 1rem;
      color: var(--primary-color);
      font-size: 0.75rem;
      line-height: 0;
      padding: 0.5rem;
      position: absolute;
      left: 10px;
      top: -5px;
    }
  }
}

.checkout-floating-btn:hover{
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

@keyframes left1phase {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-15deg);
    width: 25px;
    height: 25px;
  }
}

@keyframes right2phase {
  from {
    transform: rotate(-15deg);
    width: 25px;
    height: 25px;
  }
  to {
    transform: rotate(15deg);
    width: 25px;
    height: 25px;
  }
}

@keyframes center3phase {
  from {
    transform: rotate(15deg);
    width: 25px;
    height: 25px;
  }
  to {
    transform: rotate(0deg);
  }
}

#cart-icon.animate,
#floating-cart-icon.animate {
  -webkit-animation: left1phase 0.3s linear, right2phase 0.3s linear 0.3s,
    center3phase 0.3s linear 0.6s;
  animation: left1phase 0.3s linear, right2phase 0.3s linear 0.3s,
    center3phase 0.3s linear 0.6s;
}

.floatingbutton__cart__details {
  background: #fff;
  border: 1px solid #555;
  position: absolute;
  bottom: 5px;
  right: 0px;
  color: black;
  width: 350px;
  @media (max-width: 1500px) {
    right: 0;
  }
}

.fixed-cart__detail {
  position: absolute;
  background: #fff;
  top: 0.65rem;
  color: black;
  width: 350px;
  right: -175px;
  @media (max-width: 1500px) {
    right: 0;
  }

}

.cart-detail {
  ol {
    list-style: none;
    padding: 0.5rem 0.75rem;
    overflow-x: hidden !important;
    max-height: 300px;
  }

  li {
    border-bottom: 1px dotted #dedede;
    margin-top: 0.5rem;
    min-width: 220px;
    padding-bottom: 0.25rem;
    position: relative;
  }
  .btn-proceder-compra,.btn-proceder-compra:hover,.btn-proceder-compra:active {
    border-radius: 0;
    color: white;
  }
  .btn.btn-delete{
    padding: 0px 5px;
  }

  &__photo {
    grid-row-end: span 2;
    img {
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 0;
    width: 90%;
    color: black !important;
    font-weight: bold;
  }
  &__price {
    font-weight: bold;
  }

  .esige-quantity-select{
    justify-content: center;
  }
}

.tooltip.show {
  opacity: 1 !important;
}
.tooltip.show.bs-tooltip-auto .tooltip-inner {
  background: white;
}
.tooltip.show.bs-tooltip-auto .arrow::before {
  border-bottom-color: white;
  display: none;
}

.fixed-cart {
  &__button {
    position: relative;
  }
  &__svg {
    max-width: 24px;
    height: auto;
    fill: rgba(255, 255, 255, 0.5);
  }
  &__svg:hover {
    fill: #fff;
  }
  &__number {
    background: rgba(255, 255, 255, 0.85);
    border-radius: 1rem;
    color: #274463;
    font-size: 0.85rem;
    line-height: 0.35rem;
    padding: 0.5rem;
    position: absolute;
    top: -5px;
    z-index: 1000;
  }
}
