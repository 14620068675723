.adn-producto-box {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0.35rem;
  box-shadow: 0rem 0.25rem .25rem transparent;
  transition: box-shadow .25s ease-in-out;
  padding: 0rem;

  &:hover {
    box-shadow: 0rem 0.25rem 1rem #d4d4d4;

    .adn-producto-box__img {
      transform: scale(1.075);
    }
  }

  &__data {
    padding: 0rem;
  }

  &__figure {
    text-align: center;
  }

  &__img {
    max-width: 100%;
    min-height: 300px;
    max-height: 300px;
    display: block;
    margin: auto;

    transition: transform 1s ease-in-out;
  }

  &__off {
    position: absolute;
    left: 0;
    z-index: 2;
    background: #f57e00;
    padding: 0.6rem;
    color: #fff;
    line-height: 1rem;
    border-radius: 0 1.5rem 1.5rem 1.5rem;
  }

  &__title {
    .nav-link {
      align-content: center;
      align-items: center;
      color: rgb(32, 32, 32);
      cursor: pointer;
      display: flex;
      font-size: 1.1rem;
      height: 48px;
      line-height: 1.2rem;
      margin-bottom: .5rem;
      justify-content: center;
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 1px;
    position: absolute;
    top: .25rem;
    right: -.3rem;
    z-index: 9999;

    .nav-link {
      line-height: 1rem;
      padding: 1px;
      cursor: pointer;
      color: black;
      font-size: 1rem;
      font-weight: bolder;
    }
  }

  &__prices {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: .75rem;

    .priceBanned {
      align-items: center;
      color: #9B9B9B;
      text-decoration: line-through;
      min-height: 25px;
      line-height: 1.1rem;
      font-weight: 700;
      text-align: center;
      padding: 0;
      padding-top: 2px;
    }

    .price {

      color: var(--second-color);
      min-height: 25px;
      line-height: 1.25rem;
      font-weight: 700;
      text-align: center;
      font-size: 1.25rem;
    }
  }


  .btn {
    width: 100%;
    text-transform: uppercase;
  }

  .button-comprar {
    background-color: darkorange;
    border-color: darkorange;
    opacity: 0.9;
  }

  .button-comprar:hover,
  .button-comprar:active {
    opacity: 1 !important;
    background-color: darkorange !important;
    border-color: darkorange !important;
  }

  .carousel-item {
    img {
      margin-top: .25rem;
      object-fit: contain;
    }
  }
  .carousel-control-next {
    background: #e9e8e8;
    border-radius: 10px 0px 0px 10px;
    right: 0px;
    opacity: 0.4;

    .carousel-control-next-icon {
      // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57e00' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
  }

  .carousel-control-prev {
    background: #e9e8e8;
    border-radius: 0px 10px 10px 0px;
    left: 0px;
    opacity: 0.4;

    .carousel-control-prev-icon {
      // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57e00' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    background: transparent;
  }
  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    opacity: 1;
  }
}
