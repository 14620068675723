.banner {
  background: #cf112b;
  box-shadow: 0 .1rem .5rem rgba(83, 83, 83, 0.452);
  padding: .5rem;
  // margin-bottom: 2rem;
  position: relative;
  z-index: 100;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 170px;
    // span {
    //   font-size: 1.1rem;
    //   font-weight: bold;
    //   letter-spacing: .5rem;
    //   margin: .25rem 0 0 .25rem;
    //   background: #f57e20;
    //   color: #fff;
    //   line-height: 1.4rem;
    //   padding: 0px auto;
    //   border-radius: .4rem;
    //   width: 100%;
    //   max-width: 170px;
    //   text-align: center;
    // }

    &:hover {
      text-decoration: none;
    }
  }

  h2, h6 {
    color: #fff;
  }

  h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    margin-bottom: 0;
  }
  h6 {
    margin-bottom: 0;
  }

}

.banner + .navigationbanner {
  margin: -2rem 0 3rem;
}

.navigationbanner {
  background: #f57e20;
  margin: -2rem 0 3rem;
  padding: 4rem 0;
  position: relative;

  &::before {
    content: '';
    background: url(../../assets/img/site/bgBanner.jpg) center/cover no-repeat;
    height: auto;
    opacity: .25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: #fff;
    text-shadow: 2px 2px 4px #aa4b00c4;
    margin-bottom: 1rem;
  }

  .btn-warning {
    font-weight: bold;
    font-size: 1rem;
    padding: .5rem 4rem;
  }
}
