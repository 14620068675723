.cart-articulo-titulo{
     font-size: 1.3rem !important;
}
.bg-card-carrito{
     background-color: #f0f3f5;
}
.esige-cart {
     background: white;
     box-shadow: 1px 1px 3px #dcdcdc;
     .esige-cart-row {
          flex: 0 0 100%;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .esige-cart-loader {
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translateX(-50%);
          }
          .esige-cart-container {
               &.esige-cart-loading {
                    filter: blur(4px);
               }
               flex: 0 0 100%;
               display: flex;
               flex-wrap: wrap;
               .esige-cart-image {
                    display: flex;
                    img {
                         margin: 0 auto;
                         max-height: 100px;
                         max-width: 100%;
                         object-fit: contain;
                    }
               }
               .esige-cart-title {
                    display: flex;
                    h3,
                    .h3 {
                         margin: auto 0;
                         /*
                         @include media-breakpoint-down("md") {
                              font-size: 1rem;
                         }
                         */
                    }
                    .esige-quantity-select {
                         margin-top: 10px;
                         margin-bottom: 10px;
                    }
               }
               .esige-cart-price {
                    display: flex;
                    font-size: 3rem;
                    font-weight: 600;
               }
               .esige-panel-buttons{
                    .btn-delete{
                         height: 2.25em ;
                         min-width: 121px;
                         width:121px;
                         @media screen and (min-width: 768px) {
                              width: 140px;
                         }
                    }
               }
          }
     }

     .mercadopago-button {
          padding: .5rem 3rem;
          font-size: 1.25rem;
          line-height: 1.5;
          border-radius: .3rem;
          display: inline-block;
     }
     .mercadopago-button::before{
          content:" ";
          background-image: url('https://http2.mlstatic.com/ui/navigation/5.3.5/mercadopago/logo__small.png');
          background-repeat: no-repeat;
          background-size: contain;
          padding-left: 30px;
          padding-right: 30px;
     }
}
.checkbox-big .cr{
     position: relative;
     display: inline-block;
     border: 1px solid #a9a9a9;
     border-radius: .2em;
     width: 1.3em;
     height: 1.3em;
     float: left;
     margin-right: .5em;
     line-height: .35rem;
}
.checkbox-big label input[type="checkbox"]{
     display: none;
}

.checkbox-big label input[type="checkbox"] + .cr > .cr-icon {
     transform: scale(3) rotateZ(-20deg);
     opacity: 0;
     transition: all .3s ease-in;
}

.checkbox-big label input[type="checkbox"]:checked + .cr > .cr-icon {
     transform: scale(1) rotateZ(0deg);
     opacity: 1;
}

.checkbox-big label input[type="checkbox"]:disabled + .cr{
     opacity: .5;
}


.product__off {
     &-link {
          overflow: hidden;
     }
     &-cart {
          position: absolute;
          background: red;
          text-transform: uppercase;
          color: #fff;
          padding: 0 1.5rem;
          transform: rotate(-45deg) translate(0, -65px);
          font-size: .75rem;
          font-weight: 700;

          animation-duration: .35s;
          animation-delay: 1s;
          animation-fill-mode: forwards;
          animation-name: animationOferta;
     }
}

@keyframes animationOferta {
     from { transform: rotate(-45deg) translate(0, -65px); }
     to { transform: rotate(-45deg) translate(-38px, -22px); }
}
@media (max-width:600px){
     .row.esige-cart.my-4.p-4.d-flex.justify-content-center{
          padding-left: 2px !important;
          padding-right: 2px !important;
     }

}
