:root{
  --destacados-buttons-background-color: #f57e20 !important;;
  --destacados-buttons-border-color:     #f57e20 !important;
  --destacados-buttons-color:            #f57e20 !important;

  --producto-box-price-background-color: #f57e20 !important;
  --producto-box-price-border-color:     #f57e20 !important;
  --producto-box-price-color:            #f57e20 !important;

  --producto-box-off-background:         #f57e20 !important;
}

@media screen and (min-width:576px){
  .bgTienda {
    // background: url('../../assets/img/tienda/bgTitleTienda.png') right center / contain no-repeat;
    background-color: #f57e00bd !important;
  }
  .bgTienda--home {
    margin-bottom: -6rem !important;
  }
}

.filter-option{
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 12px;
  overflow: hidden;
  padding: 3px 5px 3px 26px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  transition: color 200ms ease-in;
  width: 100%;
  z-index: 2;
  &:hover {
    background-color: #ccc;
  }
}

.badge-custom {
  margin-bottom: 5px;
  margin-left: 26px;
  cursor:pointer;
	.close {
		margin-left: .25rem;
		color: inherit;
		font-size: 100%;
		text-shadow: 0 1px 0 rgba(#000, .5);
	}
}

//buttons del carrousel
.DestacadosPrevButtonBottom, .DestacadosNextButtonBottom {
	border: none
}
.destacados-boton-ver-mas{
	height: 40px;
}

//loader
.sc-htpNat{
  margin-top: 98px;
  height: calc(100vh - 98px);
}

// CartEmpty
.empty-cart-container > h1{
	color: var(--second-color) !important;
}
.empty-cart-container > div > div{
	margin-top: 3rem;
	a{
		color: var(--primary-color) !important;
	}
}


.btn-proceder-compra{
	background-color: var(--second-color) !important;
	color: white !important;
	border: 1px solid  var(--second-color) !important;
}

.btn-proceder-compra:hover{
  color: var(--second-color) !important;
	background-color: white !important;
	border: 1px solid  var(--second-color) !important;
}

.checkout-floating-btn{
  background-color: var(--second-color) !important;
  border-color: var(--second-color) !important;

  .cart {
    &__svg {
      fill: white;
    }

    &__number {
      background: white;
      border-radius: 1rem;
      color: var(--primary-color);
    }
  }
}

.checkout-floating-btn:hover{
  border-color: var(--primary-color) !important;
  background-color: var(--second-color) !important;
  .cart {
    &__svg {
      fill: var(--primary-color) !important;
    }
    &__svg:hover {
      fill: var(--primary-color) !important;
    }
    &__number {
      background: var(--primary-color) !important;
      border-radius: 1rem;
      color: white;
    }
  }
}