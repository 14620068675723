.adn-esige-producto {
  width: 100%;
  overflow-x: hidden;
  .esige-producto-controls {
    width: 100%;
    .breadcrumb {
      margin: 0px 1rem;
      border-bottom: none;
      background-color: unset !important;
      a{
        color: #555;
        text-decoration: none;
        background-color: transparent;
        font-weight: 400;
      }
      .breadcrumb-item.active a {
        color: var(--text-color);
      }
      .breadcrumb-item+.breadcrumb-item::before {
        content: '>';
      }
      .breadcrumb-loader {
        div {
          display: inline-block;
          vertical-align: text-top;
          div {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  .esige-producto-container {
    width: 100%;
    @keyframes esige-producto-animation {
      from {
        margin-left: 100%;
      }
      to {
        margin-left: 0%;
      }
    }
    animation-name: esige-producto-animation;
    animation-iteration-count: 1;
    animation-duration: .5s;
    .esige-producto-main-detail {
      border: 1px solid #dedede;
      box-shadow: 1px 1px 3px #dcdcdc;
      .esige-producto-content {
        border-right: .5px solid var(--main-color-light);
      }
      border: .5px solid var(--main-color-light);
      background-color: white;
      .esige-carousel {
        width: 100%;
        position: relative;
        max-width: 100%;
        .esige-carousel-inner {
          display: flex;
          max-width: 100%;
          transition: left .25s ease-in-out;
          left: 0%;
          overflow: visible;
          position: relative;
          width: 100%;
          .esige-carousel-item {
            position: relative;
            align-items: center;
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-perspective: 1000px;
            perspective: 1000px;
            width: 100%;
            flex: 100%;
            min-width: 100%;
            display: flex !important;
            img {
              margin-left: auto;
              margin-right: auto;
              min-height: 350px !important;
              max-height: 350px !important;
            }
            img:not(.active){
              display: none;
            }
          }
        }
        a.carousel-control-prev,
        a.carousel-control-next {
          .carousel-control-prev-icon,
          .carousel-control-next-icon {
            background-image: none;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: inherit;
            line-height: 1;
            font-family: FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            color: blue;
          }
          .carousel-control-prev-icon:before {
            content: "\F053";
          }
          .carousel-control-next-icon:before {
            content: "\F054";
          }
        }
        .carousel-control-prev:hover{
          background-color: gray;
          border-top-right-radius: 110px;
          border-bottom-right-radius: 110px;
        }
        .carousel-control-next:hover{
          background-color: gray;
          border-top-left-radius: 110px;
          border-bottom-left-radius: 110px;
        }
      }
      .esige-producto-gallery {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-end;
        .esige-producto-gallery-primary {
          padding: 30px;
          // display: flex;
          // align-items: center;
          // justify-content: center;

          img {
            object-fit: contain;
            min-height: 450px !important;
            max-height: 450px !important;
          }
        }
        ul.esige-producto-gallery-list {
          height: 100px;
          list-style: none;
          margin: 0px;
          
          li {
            padding: 5px;
            border: 1px solid var(--main-color-light);
            border-width: 1px 1px 2px 0px;
            img {
              max-height: 90px;
              max-width: 90px;
              margin: 0 auto;
              object-fit: cover;
            }
            &:hover,
            &.hover {
              border-bottom: 2px solid var(--second-color-dark);
            }
          }
        }
      }
      .esige-producto-info {

        @media screen and (max-width: 767px) {
          & {
            padding-bottom: 2.5rem;
          }
        }

        .esige-producto-title {
          @media (min-width: 1px){
            padding-top: 20px;
          }
          @media (min-width: 1400px){
            padding-top: 0;
          }

          h1 {
            @media (min-width: 768px) and (max-width: 842px){
              width: 215px;
            }
            @media (min-width: 958px) and (max-width: 992px){
              width: 250px;
            }
            font-size: 1.8rem;
            font-weight: 700;
          }
        }
        .esige-producto-prices {
          .esige-producto-prices-current-price h3 {
            font-size: 1.9rem;
            @media screen and (min-width: 767px) {
              font-size: 2.75rem;
            }

            font-weight: 600;
            color: var(--price-color);
          }
        }
        .esige-producto-short-description {
          text-align: left;
          font-size: 15px;
          line-height: 1.7rem;
          /*
          @include media-breakpoint-up("md") {
          min-height: 200px;
        }
        @include media-breakpoint-up("lg") {
        min-height: 400px;
      }
      */
    }
    .esige-producto-checkout {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      button {
        min-width: 100px;
        max-width: 100px;
        white-space: normal;
        font-weight: 400;
        font-size: 14px;
        padding: 15px;
        font-family: 'Poppins';
        @media screen and (max-width: 320px){
          padding: 15px;
        }
      }
    }
  }
}
.esige-producto-description {
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 3px #dcdcdc;
  background-color: white;
  border: .5px solid var(--main-color-light);
  background-color: white;
  .esige-producto-long-description {
    text-align: left;
    font-size: 15px;
    line-height: 1.7rem;
    h1 {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
}
}
.esige-producto-related-row {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  box-shadow: 1px 1px 3px #dcdcdc;
  background-color: white;
  .product-container {
    min-height: 0px !important;
    margin-bottom: 1rem !important;
  }
}
}

// Estilos Contenido Ficha de Productos
.esige-producto-detail {
  display: grid;
  grid-template-columns: 2fr 5fr;
  border: 1px solid #edededba;
  // grid-gap: 0 .25rem;

  span {
    padding: .55rem .3rem;
    // margin: .2rem 0;
    background-color: #e1e1e114;
    display: flex;
    align-items: center;

    &:nth-child(4n),
    &:nth-child(4n-1) {
      background-color: #dadada7d;
      border-bottom: 2px solid #ccc;
    }

    &:nth-child(odd) {
      text-transform: uppercase;
    }
  }

  &-cod {
    justify-content: space-around;
  }

  .dropdown {
    display: inline-block;
    &:first-child {
      margin-right: .75rem;
    }

    button.btn-secondary {
      background-color: #fbfbfb;
    }
  }

}

// Boton Volver
.btn-arrow-left {
  position: absolute;
  right: 0.5rem;
  top: 0.2rem;

  &:hover {
    cursor:pointer;
    filter: drop-shadow( 3px 3px 2px rgba(0,0,0,.15))
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.qtyTitle { margin-bottom: 0; }


.esige-producto-gallery-list {
  overflow: hidden;
  border-right: 1px solid #dedede;
  margin-bottom: 0;
  padding: 0 1rem .5rem 1rem;
  display: flex !important;
  justify-content: center;

  li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 16.69%;
  }
}

span.box-medidas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  p { margin-bottom: 0; }
}

.imgZoomContainer img {
  width: 1500px;
  height: 1602px;
}

@media (max-width: 600px) {
  .esige-producto-container.px-md-4.px-lg-0.mb-2{

    padding: 4px !important;
    .row.esige-producto-main-detail .col-12.col-md-7.col-lg-6.esige-producto-info.px-md-0{
      padding-left: 0;
      padding-right: 0;
      .p-3.p-md-4.p-xl-5{
        padding-left: 2px !important;
        padding-right: 2px !important;
        .esige-producto-detail.mt-4{
          font-size:0.85rem;
        }
      }
    }
    .row.esige-producto-related-row.mt-5{
      padding-left: 2px !important;
      padding-right: 2px !important;
      .col-xs-12.col-sm-12.col-md-12.col-lg-12.esige-productos-list{
        padding-left: 2px !important;
        padding-right: 2px !important;
        .row.d-flex.justify-content-center{
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .esige-productos-controls ul.nav nav { display: none; }
  .btn.btn-default.btn-arrow-left.text-white{
    border-radius: 3px!important;
    right: 0;
  }
  /*
  .btn.btn-default.btn-arrow-left.text-white:before {
    left: -11px;
    top:4px;
  }
  */
}

.App {
  background-color: #f3f3f3;
}

.esige-producto-gallery-primary {
  border-right: 1px solid #dedede;
}

.esige-producto-main-detail {
  border: 2px solid #dedede;
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0rem 0.25rem 1rem #d4d4d4;
  margin-bottom: 4rem;
}


.page-pagination {
  padding: .5rem;
  margin-bottom: 3rem;

  &__link {
    font-size: 1rem;
  }
}
.esige-carousel {
  position: relative;
  overflow: hidden;
}
.adn-esige-producto .esige-producto-main-detail .esige-carousel .esige-carousel-inner {
  display: -webkit-flex;
  display: flex;
  max-width: 100%;
  transition: left .25s ease-in-out;
  left: 0;
  overflow: visible;
  position: relative;
  width: 100%;

  & > .esige-carousel-item {
    width: 100%;
    flex: 100% 1;
    min-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .carousel-control-next-icon {
    background-image: none;
    &::before {
      content: '🡆';
      font-size: bold;
    }
  }
  .carousel-control-prev-icon {
    background-image: none;
    &::before {
      content: '🡄';
      font-size: bold;
    }
  }
}

