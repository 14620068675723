footer {
	background-color: var(--second-color);
	color: #fff;
	padding: 3rem 0 0;
	font-size: .95rem;

	h4, p, a, a:hover {
		color: #fff;
	}
	p,
	a,
	a:hover {
		margin-bottom: 0;
	}


	// & .row > div.col-md-4 {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// }

	ul.list-unstyled a {
		text-decoration: none;
	}

	@media screen and (max-width: 767px) {
		
	}

	.icon {
		height: 1.25rem;
		margin-right: .4rem;
		margin-top: -.2rem;
	}

	.line {
		border-top: 1px solid #ffffff59;
		margin-top: 1rem;
		padding: 1rem 0;
	}
}


.float {
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 160px;
	right: 15px;
	background-color: #25d366;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3215686274509804);
	z-index: 200;
	line-height: 50px;
	opacity: 0.8;
	transition: all 0.5s ease-in-out;
  
	&:hover {
	  color: #25d366;
	  background-color: #fff;
	  opacity: 1;
	}
  
	&_1 {
	  bottom: 152px;
	}
  }
  
  .my-float {
	margin-top: 9px;
  }
  
  .float_0 {
	bottom: 220px;
	background-color: #f0813c;
  
	&:hover {
	  color: #f0813c;
	}
  }
  
  .float_1 {
	bottom: 100px;
  }
  
  .float,
  .footer__copy a,
  .footer__copy a:hover {
	color: #fff;
  }