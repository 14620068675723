.page-pagination {
  background: lightgray;
  border-radius: 2rem;
  padding: 0.75rem 1rem;
  margin: 0 auto 3rem;
  width: 100%;
  @media screen and (min-width: 576px){
    width: 50% !important;
  }

  &__link {
    font-weight: 700;
    cursor: pointer;
    color: #555;
    padding: 0.35rem 0.75rem;
    margin: 0 0.05rem;
    line-height: 1.25rem;
    font-size: 1.25rem;
    display: inline;

    @media screen and (min-width: 768px) {
      padding: 0.5rem 1rem;
      margin: 0 0.25rem;
      line-height: 1.5rem;
    }

    &:hover {
      text-decoration: none;
    }
    &--active {
      background-color: #555;
      border-radius: 5rem;
      color: #fff !important;

      &:hover {
        background-color: grey;
        color: #fff;
      }
    }
    &--next {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.75rem;
      padding: 0 1rem;
      @media screen and (min-width: 768px) {
        line-height: 2.35rem;
      }
    }
    &--before {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.75rem;
      padding: 0 1rem;
      @media screen and (min-width: 768px) {
        line-height: 2.35rem;
      }
    }
  }
}
